










































import { Component, Vue } from 'vue-property-decorator'
import http from '@/shared/http'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import _ from 'lodash'
import { DataTableHeader } from 'vuetify'
import PageHeader from '@/admin/components/PageHeader.vue'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import __ from '@/shared/helpers/__'

@Component({
  methods: { __ },
  components: { PageHeader }
})
export default class Permissions extends Vue {
  data: any = null
  loading: boolean = false
  search: string = ''

  created() {
    this.loadData()
  }

  loadData() {
    http.get(`company/${ this.company.slug }/permissions`).then(response => this.data = response.data)
  }

  async submit() {
    const data: any = []

    Object.keys(this.data.roles).map(roleUuid => {
      data.push({
        role_uuid: roleUuid,
        permissions: Object.keys(this.data.permissions).map(permissionUuid => ({
          permission_uuid: permissionUuid,
          value: !! _.get(this.data.permissions, `${ permissionUuid }.values.${ roleUuid }.value`),
        }))
      })
    })

    this.loading = true
    await http.post(`company/${ this.company.slug }/update-company-permissions`, data)
      .then(async () => {
        await this.loadData()

        await this.$store.dispatch(GlobalActions.showSnackBar, {
          type: SnackBarTypes.success,
          message: __('company.views.settings.permissions-manager.table.on-success')
        })
      })
      .finally(() => this.loading = false)
  }

  getValue(item: any, header: any): boolean {
    return !! _.get(this.data.permissions, `${ item.uuid }.values.${ header.uuid }.value`)
  }

  getEditable(item: any, header: any): boolean {
    return _.get(this.data.permissions, `${ item.uuid }.values.${ header.uuid }.editable`)
  }

  hasValue(item: any, header: any): boolean {
    return _.has(this.data.permissions, `${ item.uuid }.values.${ header.uuid }.value`)
  }

  setValue(item: any) {
    const data: any = { ...this.data }
    const oldValue: boolean = this.getValue(item.item, item.header)
    _.set(data, `permissions.${ item.item.uuid }.values.${ item.header.uuid }.value`, ! oldValue)
    this.data = data
  }

  setMultiple(header: any, group: any) {
    const roleUuid: string = header.uuid
    const data: any = { ...this.data }

    const uncheckAll: boolean = group.items
      .filter((item: any) => _.get(this.data, `permissions.${ item.uuid }.values.${ roleUuid }.editable`))
      .every((item: any) => _.get(data, `permissions.${ item.uuid }.values.${ roleUuid }.value`))

    group.items
      .filter((item: any) => _.get(this.data, `permissions.${ item.uuid }.values.${ roleUuid }.editable`))
      .forEach((item: any) => {
         _.set(data, `permissions.${ item.uuid }.values.${ roleUuid }.value`, ! uncheckAll)
      })

    this.data = data
  }

  getMultipleValue(header: any, group: any) {
    const roleUuid: string = header.uuid

    return group.items
      .filter((item: any) => _.get(this.data, `permissions.${ item.uuid }.values.${ roleUuid }.editable`))
      .every((item: any) => _.get(this.data, `permissions.${ item.uuid }.values.${ roleUuid }.value`))
  }

  hasMultipleValueCheckbox(header: any, group: any) {
    const roleUuid: string = header.uuid

    return group.items
      .filter((item: any) => _.get(this.data, `permissions.${ item.uuid }.values.${ roleUuid }.editable`)).length > 0
  }

  get permissions(): any {
    return Object.keys(this.data.permissions).map(value => ({
      name: _.get(this.data.permissions, `${ value }.name`),
      value: true,
      uuid: value,
      group: _.get(this.data.permission_groups, _.get(this.data.permissions, `${ value }.permission_group_uuid`)),
      height: 10,
    }))
  }

  get roles(): DataTableHeader[] {
    return [
      {
        value: 'name',
        text: __('company.views.settings.permissions-manager.table.columns.description'),
        sortable: false,
        divider: true,
      },
      ...Object.keys(this.data.roles).map((uuid: string) => ({
          value: 'value',
          text: _.get(this.data.roles, uuid),
          uuid,
          sortable: false,
          align: 'center',
          divider: true,
        }) as DataTableHeader
      )
    ]
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
